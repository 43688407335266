@use '@angular/material' as mat;
@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';
@import '@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme';
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/searchable-dropdown/zonar-ui-searchable-dropdown.theme';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/filter/zonar-ui-filter.theme.scss';

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-searchable-dropdown-theme($theme);
  @include zonar-ui-filter-theme($theme);

  // TODO: This background color should not be custom styled
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  zui-footer-component {
    background-color: get-mat-color($background, stroked-button);

    .zui-brand-footer {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  zui-asset-filter {
    .asset-tree-dropdown-skeleton-container {
      width: initial;
    }
  }
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

.content-container {
  overflow-y: scroll;
}

.form-screen .content-container {
  margin-bottom: 64px;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}

// fix for material buttons
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button-base,
.mat-button-wrapper {
  padding: 0;
  line-height: unset;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-form-field {
  width: 100%;
}
